import dayjs from 'dayjs';
import { catchError, filter, finalize, lastValueFrom, map, Observable, switchMap, tap, timer } from 'rxjs';
import {
	Announcement,
	ANNOUNCEMENT_MGMT_REQUEST_TYPE,
	ANNOUNCEMENT_RECURRENCE,
	AnnouncementShowState,
} from 'src/app/common/announcement/announcement';
import { ApiService } from 'src/app/common/api/api.service';
import { Api, App } from 'src/environments/shared';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ENVIRONMENT, LOGGER_SERVICE, SETTINGS_SERVICE } from '@zeiss/ng-vis-common/injection-tokens';
import {
	ApiStatusMessage,
	ApiStatusMessageCorrelated,
	IObjectKeys,
	LoggerServiceProvider,
	LogLevel,
	SettingsServiceProvider,
	VpEnvironment,
} from '@zeiss/ng-vis-common/types';
import { TutorialService } from '@zeiss/ng-vis-tutorial';
import { RULE } from '@zeiss/ng-vis-vp-auth/constants';
import { RULE_PROTECT_SERVICE } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { VpRuleProtectService } from '@zeiss/ng-vis-vp-auth/services';

import { LogCat } from '../logger/log-cat.enum';
import { NgVisVpSettings } from '../settings/settings';
import { AnnouncementComponent } from './announcement.component';

const LOG_PRE = '❗️ [ANNOUNCEMENT]';

@Injectable()
export class AnnouncementApiService extends ApiService {
	private initiated = false;
	private readonly announcementsWaiting: string[] = [];

	constructor(
		public http: HttpClient,
		private dialog: MatDialog,
		private tutorial: TutorialService,
		@Inject(ENVIRONMENT) private env: VpEnvironment,
		@Inject(RULE_PROTECT_SERVICE) private vpRuleProtectService: VpRuleProtectService,
		@Inject(LOGGER_SERVICE) public logger: LoggerServiceProvider,
		@Inject(SETTINGS_SERVICE) public settingsService: SettingsServiceProvider<NgVisVpSettings>
	) {
		super(http, logger);
	}

	getAnnouncements(options: { silent?: boolean; active?: boolean } = {}): Observable<Announcement[]> {
		const id = this.StartRequest(ANNOUNCEMENT_MGMT_REQUEST_TYPE.GET_ANNOUNCEMENTS);

		const params: IObjectKeys = {};
		if (options.active) {
			params.active = true;
			params.env = this.env;
		}

		return this.http
			.get<Announcement[]>(
				Api.ESB.Root.Path[this._currentEnv] + Api.ESB.MDM.Path + Api.ESB.MDM.Endpoint.Announcement.Announcements,
				{ params, headers: { 'Authorization-Version': '1' } }
			)
			.pipe(
				map((x) => x.map((y) => new Announcement(y))),
				catchError((e) => {
					this.LogError(e, LogCat.admin, !!options.silent);
					throw e;
				}),
				finalize(() => this.EndRequest(id))
			);
	}

	getAnnouncement(id: string): Observable<Announcement> {
		const _id = this.StartRequest(ANNOUNCEMENT_MGMT_REQUEST_TYPE.GET_ANNOUNCEMENTS + '_' + id);

		return this.http
			.get<Announcement>(
				Api.ESB.Root.Path[this._currentEnv] +
					Api.ESB.MDM.Path +
					Api.ESB.MDM.Endpoint.Announcement.Announcements +
					`/${id}`
			)
			.pipe(
				map((x) => new Announcement(x)),
				tap({
					next: (resp: Announcement) => resp,
					error: (e) => this.LogError(e, LogCat.admin),
				}),
				finalize(() => this.EndRequest(_id))
			);
	}

	createAnnouncement(announcement: Announcement): Observable<ApiStatusMessageCorrelated> {
		const id = this.StartRequest(ANNOUNCEMENT_MGMT_REQUEST_TYPE.CREATE_ANNOUNCEMENT);

		return this.http
			.post<ApiStatusMessageCorrelated>(
				Api.ESB.Root.Path[this._currentEnv] + Api.ESB.MDM.Path + Api.ESB.MDM.Endpoint.Announcement.Announcements,
				announcement
			)
			.pipe(
				tap({
					next: (resp) =>
						this.logger.Log(resp.apiStatus, {
							level: LogLevel.success,
							category: LogCat.admin,
							showToast: true,
						}),
					error: (e) => this.LogError(e, LogCat.admin, undefined),
				}),
				finalize(() => this.EndRequest(id))
			);
	}

	updateAnnouncement(announcement: Announcement): Observable<ApiStatusMessage> {
		const id = this.StartRequest(ANNOUNCEMENT_MGMT_REQUEST_TYPE.UPDATE_ANNOUNCEMENT);

		return this.http
			.patch<ApiStatusMessage>(
				Api.ESB.Root.Path[this._currentEnv] +
					Api.ESB.MDM.Path +
					Api.ESB.MDM.Endpoint.Announcement.Announcements +
					`/${announcement.id}`,
				announcement
			)
			.pipe(
				tap({
					next: (resp) =>
						this.logger.Log(resp.apiStatus, {
							level: LogLevel.success,
							category: LogCat.admin,
							showToast: true,
						}),
					error: (e) => this.LogError(e, LogCat.admin),
				}),
				finalize(() => this.EndRequest(id))
			);
	}

	deleteAnnouncement(id?: string): Observable<ApiStatusMessage> {
		const _id = this.StartRequest(ANNOUNCEMENT_MGMT_REQUEST_TYPE.DELETE_ANNOUNCEMENT);

		return this.http
			.delete<ApiStatusMessage>(
				Api.ESB.Root.Path[this._currentEnv] +
					Api.ESB.MDM.Path +
					Api.ESB.MDM.Endpoint.Announcement.Announcements +
					`/${id}`
			)
			.pipe(
				tap({
					next: (resp) =>
						this.logger.Log(resp.apiStatus, {
							level: LogLevel.success,
							category: LogCat.admin,
							showToast: true,
						}),
					error: (e) => this.LogError(e, LogCat.admin),
				}),
				finalize(() => this.EndRequest(_id))
			);
	}

	async initAnnouncementObserver() {
		if (this.initiated) {
			console.warn(LOG_PRE, "Can't initiate announcement observer multiple times");
			return;
		}
		this.initiated = true;

		await lastValueFrom(timer(App.AnnouncementCheckFirstDelay));
		const isAuthorized$ = this.vpRuleProtectService.isAuthorized$({
			rule: RULE.system_functions,
			required: ['receive_announcements'],
		});
		const trigger$ = timer(0, App.AnnouncementCheckInterval).pipe(
			filter(() => !this.settingsService.settings.SystemAnnouncementsDisabled),
			filter(() => !this.Busy([ANNOUNCEMENT_MGMT_REQUEST_TYPE.GET_ANNOUNCEMENTS])),
			switchMap(() => isAuthorized$.pipe(filter((isAuthorized) => isAuthorized == true)))
		);
		trigger$
			.pipe(
				tap(() => console.debug(LOG_PRE, 'Requesting announcements now')),
				switchMap(() =>
					this.getAnnouncements({ silent: true, active: true }).pipe(
						tap((resp) => {
							console.debug(LOG_PRE, 'Announcements received', resp);
							resp.forEach((announcement) => {
								if (!this.shouldBeShown(announcement)) return;
								this.announcementsWaiting.push(announcement.id);
								this.showAnnouncement(announcement);
							});
						})
					)
				)
			)
			.subscribe();
	}

	private shouldBeShown(announcement: Announcement) {
		if (this.announcementsWaiting.includes(announcement.id)) return false;

		const state = this.announcementShowState(announcement.id);
		const appStart = dayjs(App.AppEntered);
		const startOfToday = dayjs(dayjs().format('YYYY-MM-DD'));

		if (!state.lastShown) {
			// always show announcement if not shown before
			return true;
		} else {
			switch (announcement.recurrence) {
				case ANNOUNCEMENT_RECURRENCE.EVERY_VISIT:
					// show announcement after every app start
					return dayjs(state.lastShown).isBefore(appStart);

				case ANNOUNCEMENT_RECURRENCE.ONCE_A_DAY:
					// show announcement once per day
					return dayjs(state.lastShown).isBefore(startOfToday);

				case ANNOUNCEMENT_RECURRENCE.ONCE_A_WEEK:
					// show announcement once every 7 days
					return dayjs(state.lastShown).isBefore(startOfToday.subtract(7, 'days'));

				default:
					return false;
			}
		}
	}

	private announcementShowState(id: string): AnnouncementShowState {
		return (this.settingsService.settings.SystemAnnouncementsStates ?? []).find((x) => x.id === id) ?? { id };
	}

	private setAnnouncementShowState(id: string) {
		const allStates = (this.settingsService.settings.SystemAnnouncementsStates ?? []).concat();
		const index = (this.settingsService.settings.SystemAnnouncementsStates ?? []).findIndex((x) => x.id === id);

		if (index >= 0) {
			allStates[index].lastShown = new Date();
		} else {
			allStates.push({ id: id, lastShown: new Date() });
		}

		this.settingsService.settings = { SystemAnnouncementsStates: allStates };
	}

	private showAnnouncement(announcement: Announcement) {
		const intVal = setInterval(async () => {
			// only show if NO OTHER dialogs are open
			if (this.dialog.openDialogs.length < 1 && !this.tutorial.IsOpen) {
				clearInterval(intVal);
				const sub = this.dialog
					.open(AnnouncementComponent, { data: announcement, disableClose: true })
					.afterClosed()
					.pipe(finalize(() => this.setAnnouncementShowState(announcement.id)));
				await lastValueFrom(sub);
			} else {
				console.debug(LOG_PRE, `Can't show announcement ${announcement.id}. Other dialogs are open.`);
			}
		}, 3000);
	}
}
