import { ROUTE_PATHS } from './routes-paths';
import { AUTH_GUARD } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { VpRoute } from '@zeiss/ng-vis-vp-auth/types';
import { RULE } from '@zeiss/ng-vis-vp-auth/constants';
import { vpRule } from '@zeiss/ng-vis-vp-auth/types';

export const ROUTE_CONFIG: VpRoute = {
	path: ROUTE_PATHS._,
	canActivate: [AUTH_GUARD],
	data: {
		title: 'external.admin.title',
		description: 'external.admin.desc',
		icon: 'zui-icon-user-user-user-group',
		showInMenu: true,
		rule: vpRule({
			rule: RULE.admin_announcements_functions,
			required: ['create', 'edit', 'delete'],
		}),
		routes: [
			{
				path: '',
			},
			{
				path: ROUTE_PATHS.announcement,
				canActivate: [AUTH_GUARD],
				data: {
					title: 'external.admin.routes.announcement.title',
					description: `external.admin.routes.announcement.desc`,
					icon: 'zui-icon-triangle',
					showInMenu: true,
					cache: true,
					rule: vpRule({
						rule: RULE.admin_announcements_functions,
						required: ['create', 'edit', 'delete'],
					}),
				},
			},
		],
	},
};
