import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { App } from 'src/environments/shared';
import { ANNOUNCEMENT_LEVEL, Announcement, ANNOUNCEMENT_RECURRENCE } from './announcement';
import { marked } from 'marked';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'vis-announcement',
	templateUrl: './announcement.component.html',
	styleUrls: ['./announcement.component.scss'],
})
export class AnnouncementComponent implements AfterViewInit {
	readonly ANNOUNCEMENT_LEVEL = ANNOUNCEMENT_LEVEL;
	readonly ANNOUNCEMENT_RECURRENCE = ANNOUNCEMENT_RECURRENCE;
	readonly APP = App;
	readonly secondsLeft$ = new BehaviorSubject(5);
	convertedDescription: string;

	constructor(
		public dialogRef: MatDialogRef<AnnouncementComponent>,
		@Inject(MAT_DIALOG_DATA) public data: Announcement
	) {}

	ngAfterViewInit(): void {
		const markedData = marked.setOptions({});
		this.convertedDescription = markedData.parse(this.data.description);
		setInterval(() => this.secondsLeft$.next(this.secondsLeft$.getValue() - 1), 1000);
	}
}
