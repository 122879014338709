import { VpEnvironment } from '@zeiss/ng-vis-common/types';

export class Announcement {
	id = '';
	headline = '';
	description = '';
	draft = true;
	createdAt?: Date;
	createdBy?: number;
	editAt?: Date;
	editBy?: number;
	level = ANNOUNCEMENT_LEVEL.info;
	recurrence = ANNOUNCEMENT_RECURRENCE.ONCE;
	requiredRoles: number[] = [];
	showOnDev = false;
	showOnQas = false;
	showOnPrd = false;
	showFrom?: Date;
	showTo?: Date;
	stages?: VpEnvironment[];

	constructor(init?: Partial<Announcement>) {
		Object.assign(this, init);

		if (this.createdAt) {
			this.createdAt = new Date(this.createdAt);
		}

		if (this.editAt) {
			this.editAt = new Date(this.editAt);
		}

		if (this.showFrom) {
			this.showFrom = new Date(this.showFrom);
		}

		if (this.showTo) {
			this.showTo = new Date(this.showTo);
		}

		this.stages = [];
		if (this.showOnDev) this.stages.push(VpEnvironment.DEV);
		if (this.showOnQas) this.stages.push(VpEnvironment.QAS);
		if (this.showOnPrd) this.stages.push(VpEnvironment.PRD);
	}
}

export function assertAnnouncement(value: any) {
	return <Announcement>value;
}

export enum ANNOUNCEMENT_LEVEL {
	info = 'info',
	warning = 'warning',
	critical = 'critical',
}

export enum ANNOUNCEMENT_RECURRENCE {
	EVERY_VISIT = 'EVERY_VISIT',
	ONCE_A_DAY = 'ONCE_A_DAY',
	ONCE_A_WEEK = 'ONCE_A_WEEK',
	ONCE = 'ONCE',
}

export enum ANNOUNCEMENT_MGMT_REQUEST_TYPE {
	GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS',
	CREATE_ANNOUNCEMENT = 'CREATE_ANNOUNCEMENT',
	UPDATE_ANNOUNCEMENT = 'UPDATE_ANNOUNCEMENT',
	DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT',
}

export interface AnnouncementShowState {
	id: string;
	lastShown?: Date;
}

export function sortOrder(state?: ANNOUNCEMENT_STATE) {
	switch (state) {
		case ANNOUNCEMENT_STATE.active:
			return 0;
		case ANNOUNCEMENT_STATE.upcoming:
			return 1;
		case ANNOUNCEMENT_STATE.draft:
			return 2;
		case ANNOUNCEMENT_STATE.inactive:
			return 3;
		case ANNOUNCEMENT_STATE.expired:
			return 4;
		default:
			return 5;
	}
}

export enum ANNOUNCEMENT_STATE {
	draft = 'draft',
	upcoming = 'upcoming',
	active = 'active',
	expired = 'expired',
	unknown = 'unknown',
	inactive = 'inactive',
}

export interface AnnouncementFilter {
	value: string;
	state: ANNOUNCEMENT_STATE[];
}
