<zui-ewiq-dialog
	icon-set="common"
	[attr.state]="data.level === ANNOUNCEMENT_LEVEL.critical ? 'error' : data.level"
	role="dialog"
	tabindex="0"
>
	<zui-ewiq-dialog-headline slot="headerText" role="heading">{{ data.headline }}</zui-ewiq-dialog-headline>

	<zui-ewiq-dialog-description [innerHTML]="convertedDescription"></zui-ewiq-dialog-description>

	<zui-ewiq-dialog-content slot="arbitrary" *ngIf="data.recurrence !== ANNOUNCEMENT_RECURRENCE.ONCE">
		We'll remind you of this
		<ng-container [ngSwitch]="$any(ANNOUNCEMENT_RECURRENCE)[data.recurrence]">
			<span *ngSwitchCase="ANNOUNCEMENT_RECURRENCE.EVERY_VISIT">
				every time you enter <span class="vis-nowrap">{{ APP.NameFull }}</span
				>.
			</span>

			<span *ngSwitchCase="ANNOUNCEMENT_RECURRENCE.ONCE_A_DAY"> once per day. </span>

			<span *ngSwitchCase="ANNOUNCEMENT_RECURRENCE.ONCE_A_WEEK"> once per week. </span>
		</ng-container>
	</zui-ewiq-dialog-content>

	<zui-ewiq-dialog-buttons slot="footer" role="button">
		<zui-dialog-button
			slot="primary"
			emphasis="primary-highlight"
			(click)="dialogRef.close()"
			[disabled]="(secondsLeft$ | async)! > 0"
		>
			{{ 'ok' | translate }}
			{{ (secondsLeft$ | async)! > 0 ? ' (' + (secondsLeft$ | async) + ')' : '' }}
		</zui-dialog-button>
	</zui-ewiq-dialog-buttons>
</zui-ewiq-dialog>
