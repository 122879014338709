export default {
	admin: {
		title: 'Administration',
		desc: 'Tools for managing users.',
		routes: {
			announcement: {
				desc: 'Manage announcements.',
				title: 'Announcements',
			},
		},
	},
};
